import { ref, watchEffect } from "vue";
import { projectFirestore } from "../firebase/config";
import configSetup from "../setup";

const { companyProfileName } = configSetup();

const getTintService = () => {
  const tintServices = ref(null);
  const error = ref(null);

  let collectionRef = projectFirestore
    .collection("companyProfile")
    .doc(companyProfileName)
    .collection("tintingServices")
    .orderBy("index", "asc");

  const unsub = collectionRef.onSnapshot(
    (snap) => {
      let results = [];
      snap.docs.forEach((doc) => {
        // must wait for the server to create the timestamp & send it back
        results.push({ ...doc.data(), id: doc.id });
      });

      // update values
      tintServices.value = results;
      error.value = null;
    },
    (err) => {
      console.log(err.message);
      tintServices.value = null;
      error.value = "could not fetch the data";
    }
  );
  watchEffect((onInvalidate) => {
    onInvalidate(() => unsub());
  });
  return { error, tintServices };
};

export default getTintService;
